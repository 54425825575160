import React from "react";
import Layout from "../components/layout";
import Brand from "../components/product-grid/brand";
import Breadcrumb from "../components/breadcrumb";
import ProductGridComponent from "../components/product-grid/product-grid";
import AlgoliaSearch from "algoliasearch";
import { Grid } from "@material-ui/core";
import { graphql, withPrefix } from "gatsby";
import { GetComponentByName } from "../helpers/query-data";
import { GQLQuery } from "../types";
import { GetSimplePathObj } from "../helpers/data-formatting";
import GlobalConstants, { Algolia } from "../helpers/constants";

type ProductsProps = {
  data: {
    crystallize: GQLQuery;
  };
};

/* Creates a grid of products for pages like /products/nalgene
 * - crystallize -> GraphQL query
 */
const Products = (props: ProductsProps): JSX.Element => {
  const QueryData = props.data.crystallize.catalogue;
  const PathObj = GetSimplePathObj("Products");
  const [State, SetState] = React.useState([]);
  const DescComponent = GetComponentByName(
    QueryData.components,
    GlobalConstants.Description
  );

  /* Fetches products on page load. This must be this way since
    "USA made" is stored on Algolia */
  React.useEffect(() => {
    const Client = AlgoliaSearch(Algolia.AppId, Algolia.ApiKey);
    const Index = Client.initIndex(Algolia.IndexName);
    Index.search("nalgene").then(({ hits }) => SetState(hits));
  }, ["nalgene"]);

  return (
    <Layout>
      <Breadcrumb pathObj={PathObj}></Breadcrumb>
      <Grid item xs={12} sm={4} md={3}>
        <Brand
          brand={QueryData?.name ?? ""}
          logo={{
            url: "/brand-logos/nalgene.png",
            altText: "nalgene.png",
          }}
          description={DescComponent}
        />
      </Grid>
      <ProductGridComponent gridItems={State} />
    </Layout>
  );
};

export const Query = graphql`
  query {
    crystallize {
      catalogue(path: "/nalgene") {
        name
        path
        components {
          ...PG_component
        }
      }
    }
  }

  fragment PG_component on CRYSTALLIZE_Component {
    name
    content {
      ...PG_richText
    }
  }

  fragment PG_richText on CRYSTALLIZE_RichTextContent {
    html
  }
`;

export default Products;
