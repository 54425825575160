import React from "react";
import { makeStyles } from "@material-ui/core";
import { GQLComponent, GQLRichTextContent } from "../../types";
import Image from "../image";

const UseStyles = makeStyles({
  brand: {
    textAlign: "center",
  },

  description: {
    textAlign: "left",
    marginTop: ".5rem",
  },

  image: {
    margin: "1rem 0 1.5rem 0",
  },
});

/* Returns the innerHTML for a GQL rich text component
 * - descComponent -> The rich text component to extract html from
 */
const GetDescriptionHtml = (descComponent: GQLComponent): string[] => {
  const DescCompContent = descComponent.content as GQLRichTextContent;
  return DescCompContent.html;
};

type BrandProps = {
  description: GQLComponent;
  logo: { url: string; altText: string };
  brand: string;
};

/*
 * brand -> The name of the brand
 * logo -> The logo for the brand
 * description -> Brand's description component
 */
const Brand = (props: BrandProps): JSX.Element => {
  const Classes = UseStyles();
  const DescHtml = GetDescriptionHtml(props.description);

  return (
    <div className={Classes.brand}>
      <Image url={props.logo.url} altText={props.logo.altText} />
      {DescHtml.map((node, index) => (
        <div
          key={index}
          className={`MuiTypography-root MuiTypography-body2 ${Classes.description}`}
          dangerouslySetInnerHTML={{ __html: node }}
        />
      ))}
    </div>
  );
};

export default Brand;
